import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useEffect, useState } from 'react';
import TarsService from '../services/TarsService';
import Spinner from 'react-bootstrap/Spinner';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const Rates = ({ goToChan, goToAgora }) => {

    const { GetRatesFees } = TarsService();
    const [rates, setRates] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        document.title = "Tasas y Tarifas";

        GetRatesFees().then(res => {
            let data = JSON.parse(res.data.data).cache;
            setRates(data)
            setLoading(false)

        })


        window.scrollTo(0, 0)
    }, []);

    return <>
        <Container id="ratesDesktop" >

            <Row>
                <Col xs={12} sm={12} md={6} lg={6} xl={6}>

                    <Container>
                        <Row>
                            <Col>
                                <span className="fs-header">Tasas y Tarifas</span>
                            </Col>
                        </Row>

                        <Row>


                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <p>
                                    <br />
                                    En Meddipay hacemos todo lo posible por contribuir a la transparencia   en  relación con nuestros clientes, por eso queremos poner a tu disposición información detallada sobre las tasas y tarifas de los servicios ofrecidos para ti.
                                </p>
                            </Col>

                        </Row>

                        <Row>


                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <p className="text-mp-primary fw-bold" style={{ fontSize: "25px" }}>
                                    Nuestras tasas para {loading ? <Skeleton width={50} inline={true} /> : rates.LabelMonth} {loading ? <Skeleton width={50} inline={true} /> : rates.Year}
                                </p>
                            </Col>

                        </Row>

                        <Row>


                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <p className="fw-bold" style={{ fontSize: "17px" }}>
                                    Tasas de interés remuneratorio de créditos de consumo ordinario
                                </p>
                            </Col>

                        </Row>

                        <Row>


                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <p>
                                    Desde {loading ? <Skeleton width={30} inline={true} /> : rates.LabelMinAnnualInterestRate} ({loading ? <Skeleton width={30} inline={true} /> : rates.LabelMinMonthlyInterestRate}) hasta {loading ? <Skeleton width={30} inline={true} /> : rates.LabelMaxAnnualInterestRate} ({loading ? <Skeleton width={30} inline={true} /> : rates.LabelMaxMonthlyInterestRate})
                                </p>
                                <ul>
                                    <li>Estas tasas son asignadas dependiendo del acuerdo con el aliado y del nivel de riesgo de cada cliente.</li>
                                </ul>
                            </Col>

                        </Row>


                        <Row>


                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <p className="fw-bold" style={{ fontSize: "17px" }}>
                                    Tasas de interés moratoria de créditos de consumo ordinario
                                </p>
                            </Col>

                        </Row>

                        <Row>


                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <p>
                                    {loading ? <Skeleton width={30} inline={true} /> : rates.LabelAnnualPenaltyRate} ({loading ? <Skeleton width={30} inline={true} /> : rates.LabelMonthlyPenaltyRate})
                                </p>
                                <ul>
                                    <li>
                                        La tasa de penalización se aplicara a partir del primer día de mora. Esta penalización será la tasa de usura del mes en curso menos 1% EA.
                                    </li>
                                </ul>
                            </Col>

                        </Row>


                        <Row>


                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <p className="text-mp-primary fw-bold" style={{ fontSize: "25px" }}>
                                    Plazos
                                </p>
                            </Col>

                        </Row>

                        <Row>


                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <p className="fw-bold" style={{ fontSize: "17px" }}>
                                    Nuestros plazos actuales
                                </p>
                            </Col>

                        </Row>

                        <Row>


                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <p>
                                    Mínimo {loading ? <Skeleton width={30} inline={true} /> : rates.LabelMinTerm} - Máximo {loading ? <Skeleton width={30} inline={true} /> : rates.LabelMaxTerm}
                                </p>
                                <ul>
                                    <li>
                                        Estos plazos son ofrecidos dependiendo del acuerdo con el aliado y el nivel de riesgo de cada cliente.
                                    </li>
                                </ul>
                            </Col>

                        </Row>

                        <Row>


                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <p className="text-mp-primary fw-bold" style={{ fontSize: "25px" }}>
                                    Nuestras tarifas actuales
                                </p>
                            </Col>

                        </Row>

                        <Row>


                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <p className="fw-bold" style={{ fontSize: "17px" }}>
                                    Comisión de apertura
                                </p>
                            </Col>

                        </Row>

                        <Row>


                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <p>
                                    {loading ? <Skeleton width={30} inline={true} /> : rates.OriginFee}%
                                </p>
                                <ul>
                                    <li>
                                        Es el porcentaje para cubrir los gastos de administración y gestión del préstamo. Dicha comisión puede variar entre el 0% y el 3% del importe total de la financiación. En este caso, Meddipay lo maneja al {loading ? <Skeleton width={30} inline={true} /> : rates.OriginFee}%
                                    </li>
                                </ul>
                            </Col>

                        </Row>

                        <Row>


                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <p className="fw-bold" style={{ fontSize: "17px" }}>
                                    Seguro
                                </p>
                            </Col>

                        </Row>

                        <Row>


                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>

                                <ul>
                                    <li>
                                        Es una póliza emitida por una compañía de Seguros debidamente habilitada por la Superintendencia Financiera de Colombia, con la cobertura de fallecimiento por cualquier causa e incapacidad total y permanente. Esta situación forma parte de las políticas comerciales de la institución. Puedes aportar tu póliza en la compañía de Seguros que tu elijas siempre que cumpla con los requisitos establecidos por Meddipay
                                        <br />    <br />
                                        <b>Meddipay en la actualidad NO MANEJA O EXIGE seguro alguno.</b>
                                    </li>
                                </ul>
                            </Col>

                        </Row>

                        <Row>


                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <p className="fw-bold" style={{ fontSize: "17px" }}>
                                    Cobertura (Garantía)
                                </p>
                            </Col>

                        </Row>

                        <Row>


                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <ul>
                                    <li>El valor de la cobertura dependerá del nivel de riesgo crediticio y oscila entre el 0% y el 20%
                                        <br />
                                        Es una garantía que opera como segunda fuente de pago en caso de
                                        incumplimiento por parte del cliente como deudor principal, el cual es
                                        contratado por MEDDIPAY con FGA Fondo de Garantías S.A. con
                                        autorización del cliente que corresponde a una remuneración derivada
                                        de la cobertura y que posteriormente será transferida a FGA, siendo una
                                        prestación distinta a la operación de crédito desembolsada por
                                        MEDDIPAY no reputándose para ningún efecto interés. El pago realizado
                                        por FGA en tu nombre, no significa que te encuentres a Paz y Salvo, pues
                                        podremos continuar exigiendo el pago.
                                        <br />
                                        Puedes proponer una garantía propia que cumpla con los mismos
                                        requisitos de la ofrecida por MEDDIPAY, entre los cuales se encuentran:
                                        <ol type='a'>
                                            <li>
                                                ser una entidad habilitada por la ley y sus estatutos para ser fiador de
                                                obligaciones de terceros. </li>
                                            <li>
                                                ser una persona jurídica con al menos cinco
                                                (5) años de antigüedad.    </li>
                                            <li>
                                                cobertura mínima del 90% del capital
                                                desembolsado. Los demás requisitos podrán ser consultados a través de
                                                nuestro correo <a target="_blank" rel="noreferrer" href="mailto:hola@meddipay.com">hola@meddipay.com</a>.

                                            </li>
                                        </ol>
                                    </li>
                                </ul>

                            </Col>

                        </Row>

                        <Row>


                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <p className="fw-bold" style={{ fontSize: "17px" }}>
                                    Comisión por cobranza
                                </p>
                            </Col>

                        </Row>

                        <Row>


                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <ul>
                                    <li>
                                        Estos gastos de cobranza se calcularán como un valor fijo MÁS IVA dependiendo del periodo de mora en el cual se realicen las labores de
                                        cobranza, así:
                                        <ol type="a">
                                            <li>
                                                Un valor equivalente a $10.000 más IVA si la labor de cobranza
                                                que conlleva el pago se genera entre los días 11 a 30 de mora;
                                            </li>
                                            <li>
                                                Un valor equivalente
                                                a $20.000 máas IVA si la labor de cobranza que conlleva el pago se genera entre los
                                                días 31 a 60 de mora;
                                            </li>
                                            <li>
                                                Un valor equivalente a $30.000 máas IVA si la labor de
                                                cobranza que conlleva el pago se genera entre los días 61 a 90 de mora;
                                            </li>
                                            <li>
                                                Un valor
                                                equivalente a $40.000 máas IVA si la labor de cobranza que conlleva el pago se
                                                genera entre los días 91 a 120 de mora;
                                            </li>
                                            <li>
                                                Un valor equivalente a $50.000 máas IVA si
                                                la labor de cobranza que conlleva el pago se genera del día 120 de mora en adelante.

                                            </li>

                                        </ol>
                                        Si no realizamos labores de cobranza, no se generará cobro alguno por este
                                        concepto.
                                        <br />
                                    </li>
                                </ul>
                            </Col>

                        </Row>


                        <Row>


                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <p className="text-mp-primary fw-bold" style={{ fontSize: "20px" }}>
                                    Tasas de la Superintendencia Financiera de Colombia
                                </p>
                            </Col>

                        </Row>
                        <Row>


                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <p className="text-mp-primary fw-bold" style={{ fontSize: "25px" }}>
                                    {loading ? <Skeleton width={30} inline={true} /> : rates.LabelMonth} {loading ? <Skeleton width={30} inline={true} /> : rates.Year}
                                </p>
                            </Col>

                        </Row>

                        <Row>


                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <p className="fw-bold" style={{ fontSize: "17px" }}>
                                    Interés bancario corriente para crédito de consumo y ordinario
                                </p>
                            </Col>

                        </Row>

                        <Row>


                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>

                                <p>{loading ? <Skeleton width={30} inline={true} /> : rates.LabelAnnualBankInterest} ({loading ? <Skeleton width={30} inline={true} /> : rates.LabelMonthlyBankInterest})</p>
                            </Col>

                        </Row>

                        <Row>


                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <p className="fw-bold" style={{ fontSize: "17px" }}>
                                    Interés remuneratorio o moratorio máximo para crédito de consumo y ordinario (Usura)
                                </p>
                            </Col>

                        </Row>
                        <Row>


                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>

                                <p>{loading ? <Skeleton width={30} inline={true} /> : rates.LabelAnnualUsuryInterestRate} ({loading ? <Skeleton width={30} inline={true} /> : rates.LabelMonthlyUsuryInterestRate})</p>
                            </Col>

                        </Row>
                    </Container>

                </Col>

                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                    <img className="img-fluid"
                        src="assets/images/img/ratesImg.svg"
                        alt="rates" />
                    <p>Las tasas de interés varían cada mes. Nuestra tasa de interés siempre será menor a la tasa máxima legal vigente. Si deseas consultar la tasa máxima legal vigente, te invitamos a conocer la página web de la Superintendencia Financiera de Colombia <a target="_blank" rel="noreferrer" href="https://www.superfinanciera.gov.co/inicio/sala-de-prensa/comunicados-de-prensa-/interes-bancario-corriente-10829">aquí</a>.  Así mismo, el valor de las tarifas pueden cambiar en cualquier momento.</p>
                </Col>

            </Row>




        </Container>

        <Container id="ratesMobile">
            <Row>
                <Col xs={12} sm={12} md={6} lg={6} xl={6}>

                    <Container>
                        <Row>
                            <Col>
                                <span className="fs-header">Tasas y Tarifas</span>
                            </Col>
                        </Row>

                        <Row>


                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <p>
                                    <br />
                                    En Meddipay hacemos todo lo posible por contribuir a la transparencia   en  relación con nuestros clientes, por eso queremos poner a tu disposición información detallada sobre las tasas y tarifas de los servicios ofrecidos para ti.
                                </p>
                            </Col>

                        </Row>

                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <img className="img-fluid"
                                    src="assets/images/img/ratesImg.svg"
                                    alt="rates" />
                                <p>Las tasas de interés varían cada mes. Nuestra tasa de interés siempre será menor a la tasa máxima legal vigente. Si deseas consultar la tasa máxima legal vigente, te invitamos a conocer la página web de la Superintendencia Financiera de Colombia <a target="_blank" rel="noreferrer" href="https://www.superfinanciera.gov.co/inicio/sala-de-prensa/comunicados-de-prensa-/interes-bancario-corriente-10829">aquí</a>.  Así mismo, el valor de las tarifas pueden cambiar en cualquier momento.</p>
                            </Col>
                        </Row>
                        <Row>


                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <p className="text-mp-primary fw-bold" style={{ fontSize: "25px" }}>
                                    Nuestras tasas para {loading ? <Skeleton width={30} inline={true} /> : rates.LabelMonth} {loading ? <Skeleton width={30} inline={true} /> : rates.Year}
                                </p>
                            </Col>

                        </Row>

                        <Row>


                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <p className="fw-bold" style={{ fontSize: "17px" }}>
                                    Tasas de interés remuneratorio de créditos de consumo ordinario
                                </p>
                            </Col>

                        </Row>

                        <Row>


                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <p>
                                    Desde {loading ? <Skeleton width={30} inline={true} /> : rates.LabelMinAnnualInterestRate} ({loading ? <Skeleton width={30} inline={true} /> : rates.LabelMinMonthlyInterestRate}) hasta {loading ? <Skeleton width={30} inline={true} /> : rates.LabelMaxAnnualInterestRate} ({loading ? <Skeleton width={30} inline={true} /> : rates.LabelMaxMonthlyInterestRate})
                                </p>
                                <ul>
                                    <li>Estas tasas son asignadas dependiendo del acuerdo con el aliado y del nivel de riesgo de cada cliente.</li>
                                </ul>
                            </Col>

                        </Row>


                        <Row>


                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <p className="fw-bold" style={{ fontSize: "17px" }}>
                                    Tasas de interés moratoria de créditos de consumo ordinario
                                </p>
                            </Col>

                        </Row>

                        <Row>


                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <p>
                                    {loading ? <Skeleton width={30} inline={true} /> : rates.LabelAnnualPenaltyRate} ({loading ? <Skeleton width={30} inline={true} /> : rates.LabelMonthlyPenaltyRate})
                                </p>
                                <ul>
                                    <li>
                                        La tasa de penalización se aplicara a partir del primer día de mora. Esta penalización será la tasa de usura del mes en curso menos 1% EA.
                                    </li>
                                </ul>
                            </Col>

                        </Row>


                        <Row>


                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <p className="text-mp-primary fw-bold" style={{ fontSize: "25px" }}>
                                    Plazos
                                </p>
                            </Col>

                        </Row>

                        <Row>


                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <p className="fw-bold" style={{ fontSize: "17px" }}>
                                    Nuestros plazos actuales
                                </p>
                            </Col>

                        </Row>

                        <Row>


                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <p>
                                    Mínimo {loading ? <Skeleton width={30} inline={true} /> : rates.LabelMinTerm} - Máximo {loading ? <Skeleton width={30} inline={true} /> : rates.LabelMaxTerm}
                                </p>
                                <ul>
                                    <li>
                                        Estos plazos son ofrecidos dependiendo del acuerdo con el aliado y el nivel de riesgo de cada cliente.
                                    </li>
                                </ul>
                            </Col>

                        </Row>

                        <Row>


                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <p className="text-mp-primary fw-bold" style={{ fontSize: "25px" }}>
                                    Nuestras tarifas actuales
                                </p>
                            </Col>

                        </Row>

                        <Row>


                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <p className="fw-bold" style={{ fontSize: "17px" }}>
                                    Comisión de apertura
                                </p>
                            </Col>

                        </Row>

                        <Row>


                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <p>
                                    {loading ? <Skeleton width={30} inline={true} /> : rates.OriginFee}%
                                </p>
                                <ul>
                                    <li>
                                        Es el porcentaje para cubrir los gastos de administración y gestión del préstamo. Dicha comisión puede variar entre el 0% y el 3% del importe total de la financiación. En este caso, Meddipay lo maneja al {loading ? <Skeleton width={30} inline={true} /> : rates.OriginFee}%
                                    </li>
                                </ul>
                            </Col>

                        </Row>

                        <Row>


                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <p className="fw-bold" style={{ fontSize: "17px" }}>
                                    Seguro
                                </p>
                            </Col>

                        </Row>

                        <Row>


                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>

                                <ul>
                                    <li>
                                        Es una póliza emitida por una compañía de Seguros debidamente habilitada por la Superintendencia Financiera de Colombia, con la cobertura de fallecimiento por cualquier causa e incapacidad total y permanente. Esta situación forma parte de las políticas comerciales de la institución. Puedes aportar tu póliza en la compañía de Seguros que tu elijas siempre que cumpla con los requisitos establecidos por Meddipay
                                        <br />    <br />
                                        <b>Meddipay en la actualidad NO MANEJA O EXIGE el seguro alguno.</b>
                                    </li>
                                </ul>
                            </Col>

                        </Row>

                        <Row>


                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <p className="fw-bold" style={{ fontSize: "17px" }}>
                                    Cobertura (Garantía)
                                </p>
                            </Col>

                        </Row>

                        <Row>


                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <ul>
                                    <li>El valor de la cobertura dependerá del nivel de riesgo crediticio y oscila entre el 0% y el 20%
                                        <br />
                                        Es una garantía que opera como segunda fuente de pago en caso de
                                        incumplimiento por parte del cliente como deudor principal, el cual es
                                        contratado por MEDDIPAY con FGA Fondo de Garantías S.A. con
                                        autorización del cliente que corresponde a una remuneración derivada
                                        de la cobertura y que posteriormente será transferida a FGA, siendo una
                                        prestación distinta a la operación de crédito desembolsada por
                                        MEDDIPAY no reputándose para ningún efecto interés. El pago realizado
                                        por FGA en tu nombre, no significa que te encuentres a Paz y Salvo, pues
                                        podremos continuar exigiendo el pago.
                                        <br />
                                        Puedes proponer una garantía propia que cumpla con los mismos
                                        requisitos de la ofrecida por MEDDIPAY, entre los cuales se encuentran:
                                        <ol type='a'>
                                            <li>
                                                ser una entidad habilitada por la ley y sus estatutos para ser fiador de
                                                obligaciones de terceros. </li>
                                            <li>
                                                ser una persona jurídica con al menos cinco
                                                (5) años de antigüedad.    </li>
                                            <li>
                                                cobertura mínima del 90% del capital
                                                desembolsado. Los demás requisitos podrán ser consultados a través de
                                                nuestro correo <a target="_blank" rel="noreferrer" href="mailto:hola@meddipay.com">hola@meddipay.com</a>.

                                            </li>
                                        </ol>
                                    </li>
                                </ul>
                            </Col>

                        </Row>

                        <Row>


                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <p className="fw-bold" style={{ fontSize: "17px" }}>
                                    Comisión por cobranza
                                </p>
                            </Col>

                        </Row>

                        <Row>


                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <ul>
                                    <li>
                                        Estos gastos de cobranza se calcularán como un valor fijo MÁS IVA dependiendo del periodo de mora en el cual se realicen las labores de
                                        cobranza, así:
                                        <ol type="a">
                                            <li>
                                                Un valor equivalente a $10.000 más IVA si la labor de cobranza
                                                que conlleva el pago se genera entre los días 11 a 30 de mora;
                                            </li>
                                            <li>
                                                Un valor equivalente
                                                a $20.000 máas IVA si la labor de cobranza que conlleva el pago se genera entre los
                                                días 31 a 60 de mora;
                                            </li>
                                            <li>
                                                Un valor equivalente a $30.000 máas IVA si la labor de
                                                cobranza que conlleva el pago se genera entre los días 61 a 90 de mora;
                                            </li>
                                            <li>
                                                Un valor
                                                equivalente a $40.000 máas IVA si la labor de cobranza que conlleva el pago se
                                                genera entre los días 91 a 120 de mora;
                                            </li>
                                            <li>
                                                Un valor equivalente a $50.000 máas IVA si
                                                la labor de cobranza que conlleva el pago se genera del día 120 de mora en adelante.

                                            </li>

                                        </ol>
                                        Si no realizamos labores de cobranza, no se generará cobro alguno por este
                                        concepto.
                                        <br />
                                    </li>
                                </ul>

                            </Col>

                        </Row>

                        <Row>


                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <p className="text-mp-primary fw-bold" style={{ fontSize: "20px" }}>
                                    Tasas de la Superintendencia Financiera de Colombia
                                </p>
                            </Col>

                        </Row>
                        <Row>


                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <p className="text-mp-primary fw-bold" style={{ fontSize: "25px" }}>
                                    {loading ? <Skeleton width={30} inline={true} /> : rates.LabelMonth} {loading ? <Skeleton width={30} inline={true} /> : rates.Year}
                                </p>
                            </Col>

                        </Row>

                        <Row>


                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <p className="fw-bold" style={{ fontSize: "17px" }}>
                                    Interés bancario corriente para crédito de consumo y ordinario
                                </p>
                            </Col>

                        </Row>

                        <Row>


                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>

                                <p>{loading ? <Skeleton width={30} inline={true} /> : rates.LabelAnnualBankInterest} ({loading ? <Skeleton width={30} inline={true} /> : rates.LabelMonthlyBankInterest})</p>
                            </Col>

                        </Row>

                        <Row>


                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <p className="fw-bold" style={{ fontSize: "17px" }}>
                                    Interés remuneratorio o moratorio máximo para crédito de consumo y ordinario (Usura)
                                </p>
                            </Col>

                        </Row>
                        <Row>


                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>

                                <p>{loading ? <Skeleton width={30} inline={true} /> : rates.LabelAnnualUsuryInterestRate} ({loading ? <Skeleton width={30} inline={true} /> : rates.LabelMonthlyUsuryInterestRate})</p>
                            </Col>

                        </Row>

                    </Container>

                </Col>



            </Row>




        </Container>

    </>
}

export default Rates;