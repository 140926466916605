import axios from "axios";

const TarsService = () => {

    const axiosInstance = axios.create({
        // baseURL: `https://api.meddipay.com/`, 
        baseURL: process.env.REACT_APP_URL_TARS,
    });


    const GetAlliesCache = async () => {

        const respose = await axiosInstance.get('CacheData/GetAlliesCache',
            {
                headers: {
                    'content-type': 'application/json'
                }
            })
            .then(data => {
                return data;
            })
            .catch((error) => {

                if (error.response) {
                    return error.response; // => the response payload 
                }
                return { status: 500, data: { message: "ERROR" } }


            });
        return respose;
    };

    const GetRatesFees = async () => {

        const respose = await axiosInstance.get('CacheData/GetRatesFees',
            {
                headers: {
                    'content-type': 'application/json'
                }
            })
            .then(data => {
                return data;
            })
            .catch((error) => {

                if (error.response) {
                    return error.response; // => the response payload 
                }
                return { status: 500, data: { message: "ERROR" } }


            });
        return respose;
    };

    const GetReferenceInfo = async (Reference) => {

        const respose = await axiosInstance.get('SKAL/Customer/GetReferenceInfo?Reference=' + Reference,
            {
                headers: {
                    'content-type': 'application/json'
                }
            })
            .then(data => {
                return data;
            })
            .catch((error) => {

                if (error.response) {
                    return error.response; // => the response payload 
                }
                return { status: 500, data: { message: "ERROR" } }


            });
        return respose;
    };


    const CustomPaymentAmount = async (body) => {

        const respose = await axiosInstance.post('SKAL/Customer/CustomPaymentAmount',
            body,
            {
                headers: {
                    'content-type': 'application/json'
                }
            })
            .then(data => {
                return data;
            })
            .catch((error) => {

                if (error.response) {
                    return error.response; // => the response payload 
                }
                return { status: 500, data: { message: "ERROR" } }


            });
        return respose;
    };


    const GetBankList = async () => {

        const respose = await axiosInstance.get('SKAL/Customer/GetBankList',
            {
                headers: {
                    'content-type': 'application/json'
                }
            })
            .then(data => {
                return data;
            })
            .catch((error) => {

                if (error.response) {
                    return error.response; // => the response payload 
                }
                return { status: 500, data: { message: "ERROR" } }


            });
        return respose;
    };

    const GetPSEUrl = async (app, bank, originationReference, paymentReference, transactionValue, paymentDescription) => {

        const respose = await axiosInstance.get(`SKAL/Customer/GetPSEUrl?App=${app}&FinancialInstitutionCode=${bank}&OriginationReference=${originationReference}&PaymentReference=${paymentReference}&TransactionValue=${transactionValue}&PaymentDescription=${paymentDescription}`,
            {
                headers: {
                    'content-type': 'application/json'
                }
            })
            .then(data => {
                return data;
            })
            .catch((error) => {

                if (error.response) {
                    return error.response; // => the response payload 
                }
                return { status: 500, data: { message: "ERROR" } }


            });
        return respose;
    };

    const CreateNequiPayment = async (PhoneNumber, originationReference, paymentReference, transactionValue, type) => {

        const respose = await axiosInstance.get(`/SKAL/Customer/CreateNequiPayment?PhoneNumber=${PhoneNumber}&OriginationReference=${originationReference}&PaymentReference=${paymentReference}&TransactionValue=${transactionValue}&Type=${type}`,
            {
                headers: {
                    'content-type': 'application/json'
                }
            })
            .then(data => {
                return data;
            })
            .catch((error) => {

                if (error.response) {
                    return error.response; // => the response payload 
                }
                return { status: 500, data: { message: "ERROR" } }


            });
        return respose;
    };

    const CancelNequiPayment = async (PhoneNumber, originationReference, paymentReference, transactionId) => {

        const respose = await axiosInstance.get(`/SKAL/Customer/CancelNequiPayment?PhoneNumber=${PhoneNumber}&OriginationReference=${originationReference}&PaymentReference=${paymentReference}&TransactionId=${transactionId}`,
            {
                headers: {
                    'content-type': 'application/json'
                }
            })
            .then(data => {
                return data;
            })
            .catch((error) => {

                if (error.response) {
                    return error.response; // => the response payload 
                }
                return { status: 500, data: { message: "ERROR" } }


            });
        return respose;
    };

    const GetPayment = async (transactionId) => {

        const respose = await axiosInstance.get('SKAL/Customer/GetPayment?transactionId=' + transactionId,
            {
                headers: {
                    'content-type': 'application/json'
                }
            })
            .then(data => {
                return data;
            })
            .catch((error) => {

                if (error.response) {
                    return error.response; // => the response payload 
                }
                return { status: 500, data: { message: "ERROR" } }


            });
        return respose;
    };

    const GetPendingDruoAccount = async (OriginationReference) => {

        const respose = await axiosInstance.get('DRUO/Customer/GetPendingDruoAccount?OriginationReference=' + OriginationReference,
            {
                headers: {
                    'content-type': 'application/json'
                }
            })
            .then(data => {
                return data;
            })
            .catch((error) => {

                if (error.response) {
                    return error.response; // => the response payload 
                }
                return { status: 500, data: { message: "ERROR" } }


            });
        return respose;
    };


    const GetNequiPayment = async (transactionId) => {

        const respose = await axiosInstance.get('SKAL/Customer/GetNequiPayment?transactionId=' + transactionId,
            {
                headers: {
                    'content-type': 'application/json'
                }
            })
            .then(data => {
                return data;
            })
            .catch((error) => {

                if (error.response) {
                    return error.response; // => the response payload 
                }
                return { status: 500, data: { message: "ERROR" } }


            });
        return respose;
    };


    const CreateDruoAccount = async (originationReference, Bank, AccountType, AccountNumber) => {

        const respose = await axiosInstance.get(`/DRUO/Customer/CreateDruoAccount?OriginationReference=${originationReference}&Bank=${Bank}&AccountType=${AccountType}&AccountNumber=${AccountNumber}`,
            {
                headers: {
                    'content-type': 'application/json'
                }
            })
            .then(data => {
                return data;
            })
            .catch((error) => {

                if (error.response) {
                    return error.response; // => the response payload 
                }
                return { status: 500, data: { message: "ERROR" } }


            });
        return respose;
    };

    return {
        GetAlliesCache, GetRatesFees, GetReferenceInfo, CustomPaymentAmount, GetBankList, GetPSEUrl, GetPayment, CreateNequiPayment, GetNequiPayment,
        CancelNequiPayment, CreateDruoAccount, GetPendingDruoAccount
    }
}

export default TarsService;