import { useEffect } from "react";

const PersonalData = ({ goToChan, goToAgora }) => {

    useEffect(() => {
        document.title = "Aviso de privacidad";
        window.scrollTo(0, 0)
    }, []);

    return (<>
        <section>
            <div className="bg-mp-primary text-white">
                <div className="container-fluid p-5 text-justify">
                    <p className="mp-title-size fw-bold">
                        Aviso Legal</p>
                </div>
            </div>



            <div className="container">


                <div className="my-5">
                    <p></p>
                    <p>
                        <b>MEDDIPAY S.A.S</b> (En adelante Meddipay), identificada con <b>NIT 901763399-5</b>, sociedad inscrita en la Cámara de Comercio de Manizales por Caldas con matricula mercantil 233118, es responsable del tratamiento de los datos personales que son recolectados y almacenados en las bases datos. Su tratamiento se realiza conforme a su <a target="_blank" href="/privacy">Política de privacidad y tratamiento de datos personales</a>, respetando lo consagrado en la Ley 1581 de 2012 y demás normas concordantes.
                    </p>
                    <p></p>
                    <p className="text-mp-primary fs-3 fw-bold">Contrato de mutuo comercial</p>
                    <p></p>
                    <p>
                        Adjunto encontrarás un modelo del contrato estándar proporcionado por Meddipay. Es importante destacar que este documento no constituye el contrato final, sino un ejemplo. El contrato definitivo, completo y con las condiciones legales pertinentes, estará disponible al momento de tomar un crédito por medio de Meddipay, para tratamientos con alguno de nuestros aliados.
                    </p>
                    <p style={{ cursor: "pointer" }} onClick={()=>window.open("assets/contratoModeloV4.pdf")}><b><i className="bi bi-filetype-pdf" style={{ color: "red", fontSize: "20px" }}></i> Contrato de mutuo comercial</b></p>
                </div>
            </div>
        </section>

        {/* <section>
            <div className="bg-mp-primary text-white">
                <div className="container-fluid p-5 text-justify">
                    <p className="mp-title-size fw-bold">
                        Aviso de privacidad de tratamiento
                    </p>
                    <p className="mp-title-size fw-bold">de datos personales</p>
                </div>
            </div>



            <div className="container">


                <div className="my-5">
                    <p></p>
                    <p>
                        Meddipay S.A.S (En adelante Meddipay), identificada con NIT
                        901763399-5, es responsable del tratamiento de los datos personales
                        que son recolectados y almacenados en las bases datos. Su
                        tratamiento se realiza conforme a su <a target="_blank" href="/privacy">Pol&iacute;tica de privacidad y
                            tratamiento de datos personales</a>, respetando lo
                        consagrado en la Ley 1581 de 2012 y dem&aacute;s normas
                        concordantes.
                    </p>
                    <p></p>
                    <p className="text-mp-primary fs-3 fw-bold">Autorizaci&oacute;n</p>
                    <p></p>
                    <p>
                        Esta es la autorizaci&oacute;n mediante la cual el usuario da su
                        consentimiento previo, expreso e informado para que Meddipay
                        almacene y procese sus datos personales, de acuerdo a la
                        Pol&iacute;tica de privacidad y tratamiento de datos personales.
                    </p>
                    <p></p>
                    <p className="text-mp-primary fs-3 fw-bold">Finalidades</p>
                    <p></p>
                    <p>
                        Los datos ser&aacute;n almacenados y tratados por Meddipay, y sus
                        terceros aliados, para las siguientes finalidades:
                    </p>
                    <p></p>
                    <p>
                        1. Recolectar y almacenar la informaci&oacute;n en nuestras bases de
                        datos.
                    </p>
                    <p>
                        2. Dar cumplimiento a las obligaciones contra&iacute;das con
                        nuestros usuarios y/o clientes.
                    </p>
                    <p>3. Prestar los servicios de forma correcta.</p>
                    <p>4. Ordenar y clasificar los datos personales suministrados.</p>
                    <p>
                        5. Verificar los datos personales suministrados, para evitar fraudes
                        o suplantaci&oacute;n de identidad.
                    </p>
                    <p>
                        6. Creaci&oacute;n y gesti&oacute;n de los perfiles y/o cuentas de
                        los usuarios y/o clientes.
                    </p>
                    <p>
                        7. Mantener contacto y comunicaci&oacute;n con los titulares de los
                        datos.
                    </p>
                    <p>
                        8. Entablar comunicaci&oacute;n con los usuarios y/o clientes, a
                        trav&eacute;s de llamadas, mensajes de texto, correo
                        electr&oacute;nico o a trav&eacute;s de cualquier otro medio de
                        comunicaci&oacute;n f&iacute;sico o digital.
                    </p>
                    <p>9. Para gesti&oacute;n administrativa, contable y fiscal.</p>
                    <p>
                        10. Analizar el perfil crediticio y de riesgo de nuestros clientes
                        y/o usuarios.
                    </p>
                    <p>
                        11. Consultar a las centrales de informaci&oacute;n de riesgo,
                        crediticia y comercial, el historial crediticio de los clientes y/o
                        usuarios.
                    </p>
                    <p>
                        12. Reportar a las centrales de riesgo incumplimiento de los
                        usuarios y/o clientes de sus obligaciones financieras.
                    </p>
                    <p>
                        13. Verificar el cumplimiento de las obligaciones de nuestros
                        clientes y/o usuarios.
                    </p>
                    <p>
                        14. Compartir con terceros, incluyendo la transferencia y
                        transmisi&oacute;n (inclusive internacional), los datos personales
                        de los usuarios y/o clientes.
                    </p>
                    <p>
                        15. Recibir informaci&oacute;n de terceros sobre nuestros clientes
                        y/o usuarios.
                    </p>
                    <p></p>
                    <p className="text-mp-primary fs-3 fw-bold">
                        Datos personales almacenados y procesados
                    </p>
                    <p></p>
                    <p></p>
                    <p>
                        Meddipay puede solicitar y almacenar datos necesarios para la
                        correcta prestaci&oacute;n de sus servicios, tales como: 1) Datos de
                        identificaci&oacute;n y de contacto, 2) informaci&oacute;n
                        financiera (Ingresos, gastos, h&aacute;bitos), 3) informaci&oacute;n
                        sobre profesi&oacute;n u oficio, 4) informaci&oacute;n
                        demogr&aacute;fica, 5) datos sensibles.
                    </p>
                    <p></p>
                    <p className="text-mp-primary fs-3 fw-bold">Derechos del titular</p>
                    <p></p>
                    <p>Los titulares de los datos tienen los siguientes derechos:</p>
                    <p>
                        1. Presentar solicitudes de actualizaci&oacute;n,
                        informaci&oacute;n, eliminaci&oacute;n de sus datos personales.
                    </p>
                    <p>
                        2. Ser informado del uso que hace Meddipay de sus datos personales.
                    </p>
                    <p>
                        3. Presentar quejas ante las autoridades competentes por violaciones
                        al r&eacute;gimen de protecci&oacute;n de datos.
                    </p>
                    <p>4. Revocar la autorizaci&oacute;n de tratamiento de datos.</p>
                    <p></p>
                    <p>
                        Los clientes y/o usuarios solo podr&aacute;n revocar la
                        autorizaci&oacute;n o suprimir los datos personales si no existe una
                        obligaci&oacute;n legal o contractual que implique conservar los
                        datos personales en las bases de datos de Meddipay.
                    </p>
                    <p></p>
                    <p></p>
                    <p className="text-mp-primary fs-3 fw-bold">
                        Acceso a la informaci&oacute;n
                    </p>
                    <p></p>
                    <p>
                        Puede tener acceso a los datos personales los titulares de la
                        informaci&oacute;n (acreditando su identidad), los causahabientes
                        (acreditando la calidad de causahabientes), los representantes o
                        apoderados de los titulares de la informaci&oacute;n (acreditando la
                        calidad de representante o apoderado)
                    </p>
                    <p></p>
                    <p>
                        Los canales de atenci&oacute;n para acceso a la informaci&oacute;n
                        son los siguientes:
                    </p>
                    <p></p>
                    <p>
                        &middot; Atenci&oacute;n virtual <a data-fr-linked="true" href="mailto:hola@meddipay.com">hola@meddipay.com</a>
                    </p>
                    <p>
                        &middot; Atenci&oacute;n presencial Carrera 28 C #71-28 Manizales
                    </p>
                </div>
            </div>
        </section> */}
    </>)

}

export default PersonalData